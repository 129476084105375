import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/info";
export const PageQuery_info_nl_termsandconditionsmdx = graphql`
        query PageQuery_info_nl_termsandconditionsmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "info"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/terms-and-conditions/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Algemene Voorwaarden",
  "path": "/info/terms-and-conditions",
  "date": "2022-05-04T00:00:00.000Z"
};
const layoutProps = {
  PageQuery_info_nl_termsandconditionsmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "algemene-voorwaarden",
      "style": {
        "position": "relative"
      }
    }}>{`Algemene Voorwaarden`}<a parentName="h1" {...{
        "href": "#algemene-voorwaarden",
        "aria-label": "algemene voorwaarden permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Laatst bijgewerkt: 29 September, 2022`}</p>
    <p>{`Lees deze voorwaarden zorgvuldig door voordat u gebruik maakt van Onze Dienst.`}</p>
    <h1 {...{
      "id": "table-of-contents",
      "style": {
        "position": "relative"
      }
    }}>{`Table of Contents`}<a parentName="h1" {...{
        "href": "#table-of-contents",
        "aria-label": "table of contents permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <div className="table-of-contents">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#algemene-voorwaarden"
          }}>{`Algemene Voorwaarden`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#definities"
          }}>{`Definities`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#vertaling-en-interpretatie"
          }}>{`Vertaling en Interpretatie`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#algemene-informatie"
          }}>{`Algemene Informatie`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#aansprakelijkheid"
          }}>{`Aansprakelijkheid`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#bepalingen"
          }}>{`Bepalingen`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#wijzigingen-in-juridische-documentatie"
          }}>{`Wijzigingen in Juridische Documentatie`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#contact"
          }}>{`Contact`}</a></li>
      </ul>
    </div>
    <h1 {...{
      "id": "definities",
      "style": {
        "position": "relative"
      }
    }}>{`Definities`}<a parentName="h1" {...{
        "href": "#definities",
        "aria-label": "definities permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <h2 {...{
      "id": "interpretatie",
      "style": {
        "position": "relative"
      }
    }}>{`Interpretatie`}<a parentName="h2" {...{
        "href": "#interpretatie",
        "aria-label": "interpretatie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`De woorden waarvan de beginletter met een hoofdletter is geschreven, hebben de betekenis die onder de volgende voorwaarden is gedefinieerd. De volgende definities hebben dezelfde betekenis, ongeacht of ze in het enkelvoud of in het meervoud voorkomen.`}</p>
    <h2 {...{
      "id": "entiteiten",
      "style": {
        "position": "relative"
      }
    }}>{`Entiteiten`}<a parentName="h2" {...{
        "href": "#entiteiten",
        "aria-label": "entiteiten permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Voor de toepassing van deze Algemene Voorwaarden:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Verbonden partij`}</strong>{` betekent een entiteit die zeggenschap heeft over, onder zeggenschap staat van of onder gemeenschappelijke zeggenschap staat met een partij, waarbij "zeggenschap" betekent het bezit van 50% of meer van de aandelen, aandelenbelangen of andere effecten die stemrecht geven voor de verkiezing van directeuren of andere leidinggevende autoriteit.`}</li>
      <li parentName="ul"><strong parentName="li">{`Land`}</strong>{` verwijst naar: Nederland`}</li>
      <li parentName="ul"><strong parentName="li">{`Bedrijf`}</strong>{` (in deze Overeenkomst aangeduid als "het Bedrijf", "Wij", "Ons" of "Onze") verwijst naar Cheesebyte, Keurenplein 4 (A1800), 1069 CD Amsterdam.`}</li>
      <li parentName="ul"><strong parentName="li">{`Device`}</strong>{` betekent elk apparaat dat toegang kan krijgen tot de Dienst, zoals een computer, een mobiele telefoon of een digitale tablet.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dienst`}</strong>{` verwijst naar de Website.`}</li>
      <li parentName="ul"><strong parentName="li">{`Voorwaarden`}</strong>{` (ook wel "Voorwaarden" genoemd) betekent deze Algemene Voorwaarden die de volledige overeenkomst vormen tussen U en het Bedrijf met betrekking tot het gebruik van de Service.`}</li>
      <li parentName="ul"><strong parentName="li">{`Social Media Diensten van derden`}</strong>{` betekent alle diensten of inhoud (inclusief gegevens, informatie, producten of diensten) die door een derde partij worden aangeboden en door de Service kunnen worden weergegeven, opgenomen of beschikbaar gesteld.`}</li>
      <li parentName="ul"><strong parentName="li">{`Website`}</strong>{` verwijst naar Cheesebyte, toegankelijk via `}<a parentName="li" {...{
          "href": "https://www.cheesebyte.nl"
        }}>{`www.cheesebyte.nl`}</a></li>
      <li parentName="ul"><strong parentName="li">{`U`}</strong>{` betekent de persoon die toegang heeft tot of gebruik maakt van de Dienst, of het bedrijf, of een andere juridische entiteit namens welke een dergelijke persoon toegang heeft tot of gebruik maakt van de Dienst, voor zover van toepassing.`}</li>
    </ul>
    <h1 {...{
      "id": "vertaling-en-interpretatie",
      "style": {
        "position": "relative"
      }
    }}>{`Vertaling en Interpretatie`}<a parentName="h1" {...{
        "href": "#vertaling-en-interpretatie",
        "aria-label": "vertaling en interpretatie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Deze documentatie kan vertaald zijn indien Wij ze voor U beschikbaar hebben gesteld op onze Dienst. U gaat ermee akkoord dat de originele Engelse tekst voorrang heeft in het geval van een geschil.`}</p>
    <h1 {...{
      "id": "algemene-informatie",
      "style": {
        "position": "relative"
      }
    }}>{`Algemene Informatie`}<a parentName="h1" {...{
        "href": "#algemene-informatie",
        "aria-label": "algemene informatie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Dit zijn de Algemene Voorwaarden voor het gebruik van deze Dienst en de overeenkomst die tussen U en het Bedrijf van kracht is. In deze Algemene Voorwaarden worden de rechten en plichten van alle gebruikers met betrekking tot het gebruik van de Service uiteengezet.`}</p>
    <p>{`Uw toegang tot en gebruik van de Service is afhankelijk van Uw aanvaarding van en naleving van deze Algemene Voorwaarden. Deze Algemene Voorwaarden zijn van toepassing op alle bezoekers, gebruikers en anderen die toegang hebben tot of gebruik maken van de Dienst.`}</p>
    <p>{`Door toegang tot of gebruik van de Service gaat U ermee akkoord gebonden te zijn aan deze Algemene Voorwaarden. Als U het niet eens bent met enig deel van deze Algemene Voorwaarden, dan mag U geen gebruik maken van de Dienst.`}</p>
    <p>{`U verklaart dat U ouder bent dan 18 jaar. De Onderneming staat niet toe dat personen jonger dan 18 jaar de Service gebruiken.`}</p>
    <p>{`Uw toegang tot en gebruik van de Service is ook afhankelijk van Uw acceptatie van en naleving van het Privacybeleid van het Bedrijf. Ons Privacybeleid beschrijft ons beleid en onze procedures met betrekking tot het verzamelen, gebruiken en openbaar maken van Uw persoonlijke informatie wanneer U de Applicatie of de Website gebruikt en informeert U over Uw privacyrechten en hoe de wet U beschermt. Gelieve zorgvuldig Ons Privacybeleid te lezen alvorens Onze Dienst te gebruiken.`}</p>
    <h2 {...{
      "id": "links-naar-andere-websites",
      "style": {
        "position": "relative"
      }
    }}>{`Links naar andere websites`}<a parentName="h2" {...{
        "href": "#links-naar-andere-websites",
        "aria-label": "links naar andere websites permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Onze Service kan links bevatten naar websites of diensten van derden die geen eigendom zijn van of niet worden gecontroleerd door het Bedrijf.`}</p>
    <p>{`Het Bedrijf heeft geen controle over, en aanvaardt geen verantwoordelijkheid voor, de inhoud, het privacybeleid of de praktijken van websites of diensten van derden. U erkent verder en gaat ermee akkoord dat de Onderneming niet verantwoordelijk of aansprakelijk is, direct of indirect, voor enige schade of verlies veroorzaakt of beweerd te zijn veroorzaakt door of in verband met het gebruik van of het vertrouwen op dergelijke inhoud, goederen of diensten beschikbaar op of via dergelijke websites of diensten.`}</p>
    <p>{`Wij raden U ten sterkste aan de voorwaarden en het privacybeleid te lezen van alle websites of diensten van derden die U bezoekt.`}</p>
    <h2 {...{
      "id": "beëindiging",
      "style": {
        "position": "relative"
      }
    }}>{`Beëindiging`}<a parentName="h2" {...{
        "href": "#be%C3%ABindiging",
        "aria-label": "beëindiging permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Wij kunnen Uw toegang met onmiddellijke ingang beëindigen of opschorten, zonder voorafgaande kennisgeving of aansprakelijkheid, om welke reden dan ook, inclusief en zonder beperking indien U deze Algemene Voorwaarden schendt.`}</p>
    <p>{`Bij beëindiging, zal Uw recht om de Dienst te gebruiken onmiddellijk ophouden.`}</p>
    <h1 {...{
      "id": "aansprakelijkheid",
      "style": {
        "position": "relative"
      }
    }}>{`Aansprakelijkheid`}<a parentName="h1" {...{
        "href": "#aansprakelijkheid",
        "aria-label": "aansprakelijkheid permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Niettegenstaande enige schade die U zou kunnen oplopen, zal de volledige aansprakelijkheid van het Bedrijf en elk van zijn leveranciers onder enige bepaling van deze Voorwaarden en Uw exclusieve rechtsmiddel voor al het voorgaande beperkt zijn tot het bedrag dat U werkelijk betaald heeft via de Service of 100 USD als U niets gekocht heeft via de Service.`}</p>
    <p>{`Voor zover maximaal toegestaan door de toepasselijke wetgeving, in geen geval zal het Bedrijf of haar leveranciers aansprakelijk zijn voor enige bijzondere, incidentele, indirecte of gevolgschade (met inbegrip van, maar niet beperkt tot, schade voor verlies van winst, verlies van gegevens of andere informatie, voor bedrijfsonderbreking, voor persoonlijk letsel, verlies van privacy voortvloeiend uit of op enigerlei wijze verband houdend met het gebruik van of de onmogelijkheid tot gebruik van de Service, software van derden en/of hardware van derden gebruikt met de Service, of anderszins in verband met enige bepaling van deze Voorwaarden), zelfs indien het Bedrijf of enige leverancier op de hoogte is gesteld van de mogelijkheid van dergelijke schade en zelfs indien de remedie niet aan haar wezenlijke doel beantwoordt.`}</p>
    <p>{`In sommige staten is de uitsluiting van impliciete garanties of de beperking van aansprakelijkheid voor incidentele schade of gevolgschade niet toegestaan, wat betekent dat sommige van de bovenstaande beperkingen mogelijk niet van toepassing zijn. In deze staten zal de aansprakelijkheid van elke partij beperkt zijn tot de grootste mate toegestaan door de wet.`}</p>
    <h2 {...{
      "id": "dienstenverklaring",
      "style": {
        "position": "relative"
      }
    }}>{`Dienstenverklaring`}<a parentName="h2" {...{
        "href": "#dienstenverklaring",
        "aria-label": "dienstenverklaring permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`De Dienst wordt U aangeboden "IN DE HUIDIGE STAAT" en "ZOALS BESCHIKBAAR GEMAAKT" en met alle fouten en gebreken zonder enige vorm van garantie. Voor zover maximaal is toegestaan onder de toepasselijke wetgeving, wijst het Bedrijf, namens zichzelf en namens zijn Filialen en zijn en hun respectieve licentiegevers en dienstverleners, uitdrukkelijk alle garanties af, hetzij expliciet, impliciet, wettelijk of anderszins, met betrekking tot de Service, met inbegrip van alle impliciete garanties van verkoopbaarheid, geschiktheid voor een bepaald doel, titel en niet-inbreuk, en garanties die kunnen voortvloeien uit de gang van zaken, het verloop van de prestaties, het gebruik of de handelspraktijk. Zonder beperking op het voorgaande geeft het Bedrijf geen garantie of toezegging en doet geen enkele toezegging dat de Service aan uw eisen zal voldoen, de beoogde resultaten zal bereiken, compatibel zal zijn of zal werken met andere software, toepassingen, systemen of diensten, zonder onderbreking zal werken, aan prestatie- of betrouwbaarheidsnormen zal voldoen of foutvrij zal zijn of dat eventuele fouten of defecten kunnen of zullen worden gecorrigeerd.`}</p>
    <p>{`Zonder het voorgaande te beperken, doet noch het Bedrijf, noch een van de leveranciers van het Bedrijf een verklaring of garantie van welke aard dan ook, uitdrukkelijk of impliciet: (i) met betrekking tot de werking of beschikbaarheid van de Service, of de informatie, inhoud en materialen of producten die daarin zijn opgenomen; (ii) dat de Service ononderbroken of vrij van fouten zal zijn; (iii) met betrekking tot de nauwkeurigheid, betrouwbaarheid of actualiteit van informatie of inhoud die via de Service wordt verstrekt; of (iv) dat de Service, de servers, de inhoud of e-mails die van of namens het Bedrijf worden verzonden, vrij zijn van virussen, scripts, trojaanse paarden, wormen, malware, timebombs of andere schadelijke componenten.`}</p>
    <p>{`In sommige rechtsgebieden is de uitsluiting van bepaalde soorten garanties of beperkingen op toepasselijke wettelijke rechten van een consument niet toegestaan, zodat sommige of alle van de bovenstaande uitsluitingen en beperkingen mogelijk niet op U van toepassing zijn. Maar in een dergelijk geval zullen de uitsluitingen en beperkingen die in deze sectie worden uiteengezet, worden toegepast in de grootste mate die volgens de toepasselijke wetgeving kan worden afgedwongen.`}</p>
    <h2 {...{
      "id": "toepasselijk-recht",
      "style": {
        "position": "relative"
      }
    }}>{`Toepasselijk recht`}<a parentName="h2" {...{
        "href": "#toepasselijk-recht",
        "aria-label": "toepasselijk recht permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`De wetten van het Land, met uitsluiting van zijn conflicten van wettelijke regels, zullen van toepassing zijn op deze Voorwaarden en Uw gebruik van de Dienst. Uw gebruik van de Applicatie kan ook onderworpen zijn aan andere lokale, staats, nationale of internationale wetten.`}</p>
    <h2 {...{
      "id": "geschillenbeslechting",
      "style": {
        "position": "relative"
      }
    }}>{`Geschillenbeslechting`}<a parentName="h2" {...{
        "href": "#geschillenbeslechting",
        "aria-label": "geschillenbeslechting permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Als U enige zorg of geschil heeft over de Service, gaat U ermee akkoord om eerst te proberen het geschil informeel op te lossen door contact op te nemen met het Bedrijf.`}</p>
    <h2 {...{
      "id": "voor-gebruikers-van-de-europese-unie-eu",
      "style": {
        "position": "relative"
      }
    }}>{`Voor Gebruikers van de Europese Unie (EU)`}<a parentName="h2" {...{
        "href": "#voor-gebruikers-van-de-europese-unie-eu",
        "aria-label": "voor gebruikers van de europese unie eu permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Als U een consument uit de Europese Unie bent, zult U profiteren van alle dwingende bepalingen van de wet van het land waarin U woonachtig bent.`}</p>
    <h2 {...{
      "id": "wettelijke-naleving-verenigde-staten",
      "style": {
        "position": "relative"
      }
    }}>{`Wettelijke Naleving Verenigde Staten`}<a parentName="h2" {...{
        "href": "#wettelijke-naleving-verenigde-staten",
        "aria-label": "wettelijke naleving verenigde staten permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`U verklaart en garandeert dat (i) U zich niet in een land bevindt dat onderworpen is aan het embargo van de regering van de Verenigde Staten, of dat door de regering van de Verenigde Staten als een "terroristen ondersteunend" land is aangewezen, en (ii) U niet op een lijst van de regering van de Verenigde Staten van verboden of beperkte partijen staat.`}</p>
    <h1 {...{
      "id": "bepalingen",
      "style": {
        "position": "relative"
      }
    }}>{`Bepalingen`}<a parentName="h1" {...{
        "href": "#bepalingen",
        "aria-label": "bepalingen permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <h2 {...{
      "id": "scheidbaarheid",
      "style": {
        "position": "relative"
      }
    }}>{`Scheidbaarheid`}<a parentName="h2" {...{
        "href": "#scheidbaarheid",
        "aria-label": "scheidbaarheid permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Indien een bepaling van deze Voorwaarden niet-afdwingbaar of ongeldig wordt verklaard, zal een dergelijke bepaling worden gewijzigd en geïnterpreteerd om de doelstellingen van een dergelijke bepaling te bereiken voor zover dit mogelijk is onder de toepasselijke wetgeving en de overige bepalingen zullen volledig van kracht en van kracht blijven.`}</p>
    <h2 {...{
      "id": "verklaring-van-afstand",
      "style": {
        "position": "relative"
      }
    }}>{`Verklaring van afstand`}<a parentName="h2" {...{
        "href": "#verklaring-van-afstand",
        "aria-label": "verklaring van afstand permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Behalve zoals hierin bepaald, zal het niet uitoefenen van een recht of het niet eisen van de uitvoering van een verplichting onder deze Voorwaarden geen invloed hebben op de mogelijkheid van een partij om een dergelijk recht uit te oefenen of een dergelijke uitvoering op enig moment daarna te eisen, noch zal de verklaring van afstand van een schending een verklaring van afstand van enige volgende schending vormen.`}</p>
    <h1 {...{
      "id": "wijzigingen-in-juridische-documentatie",
      "style": {
        "position": "relative"
      }
    }}>{`Wijzigingen in Juridische Documentatie`}<a parentName="h1" {...{
        "href": "#wijzigingen-in-juridische-documentatie",
        "aria-label": "wijzigingen in juridische documentatie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Wij behouden ons het recht voor, naar Ons eigen goeddunken, deze Voorwaarden te allen tijde te wijzigen of te vervangen. Als een herziening materieel is, zullen wij redelijke inspanningen leveren om ten minste 30 dagen van tevoren kennis te geven van het van kracht worden van de nieuwe voorwaarden. Wat een materiële wijziging is, zal naar eigen goeddunken worden bepaald.`}</p>
    <p>{`Door toegang te blijven houden tot of gebruik te blijven maken van onze service nadat deze herzieningen van kracht zijn geworden, gaat U ermee akkoord gebonden te zijn aan de herziene voorwaarden. Indien U niet akkoord gaat met de nieuwe voorwaarden, geheel of gedeeltelijk, stop dan met het gebruik van de website en de Dienst.`}</p>
    <h1 {...{
      "id": "contact",
      "style": {
        "position": "relative"
      }
    }}>{`Contact`}<a parentName="h1" {...{
        "href": "#contact",
        "aria-label": "contact permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Als U vragen heeft over deze Algemene Voorwaarden, kunt U contact met ons opnemen:`}</p>
    <ul>
      <li parentName="ul">{`Per e-mail: zie voettekst op deze website.`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      